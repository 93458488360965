import React from 'react'

const Arrow = () => {
  return (
    <svg role='img' viewBox='0 0 24 24' fill='inherit' className='svg-fill'>
      <path d='M15.7133 10.7045C16.1009 10.3111 16.0962 9.67795 15.7028 9.29033C15.3094 8.90271 14.6762 8.9074 14.2886 9.3008L8.99727 14.6711V1C8.99727 0.447715 8.54956 0 7.99727 0C7.44499 0 6.99727 0.447716 6.99727 1V14.6646L1.71232 9.3008C1.3247 8.9074 0.691555 8.90271 0.298149 9.29033C-0.0952566 9.67795 -0.0999449 10.3111 0.287676 10.7045L7.11006 17.6287C7.59967 18.1256 8.40126 18.1256 8.89087 17.6287L15.7133 10.7045Z' />
    </svg>
  )
}

export default Arrow