module.exports = {
  author: "Chad Bergman",
  siteURL: "https://chadbergman.design",
  siteTitle: "Chad Bergman | Design System Advocate + Lead Designer",
  siteShortTitle: "CB",
  siteDescription:
    "Hi! I'm Chad. I'm a senior designer who enjoys digging in to code and specializes in design systems, tooling, and empowering individuals and teams.",
  siteLanguage: "en_US",
  siteIcon: "images/memoji.png",
  socialMedia: [
    {
      name: "LinkedIn",
      username: 'chad-bergman',
      url: "https://linkedin.com/in/chad-bergman/",
    },
    {
      name: "Twitter",
      username: 'dotdude',
      url: "https://twitter.com/dotdude",
    },
    // {
    //   name: "GitHub",
    //   username: 'chadbergman',
    //   url: "https://github.com/chadbergman",
    // },
  ],
  navLinks: {
    menu: [
      {
        name: "Back to top",
        url: "/#hero",
      },
      {
        name: "Experience",
        url: "/#experience",
      },
      {
        name: "About",
        url: "/#about",
      },
      {
        name: "Connect",
        url: "/#connect",
      },
    ],
  },
}
