import React from 'react'
import PropTypes from 'prop-types'
import '../styles/global.scss'
import Header from './Header'
import SEO from './Seo'
import SectionNav from './SectionNav'
import Footer from './Footer'

const Layout = ({ children }) => {
  return (
    <div>
      <SEO />
      <Header />
      <SectionNav />
      <main id='main'>{children}</main>
      <Footer />
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.any,
}

export default Layout