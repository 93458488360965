import React from 'react'
import { AnchorLink } from 'gatsby-plugin-anchor-links'
import { navLinks } from '../config'

const SectionNav = () => {
    const { menu } = navLinks

    return (
      <nav>
        <ul className='section-nav'>
        {menu.map(({ name, url }, key) => {
          return (
            <li key={key}>
              <AnchorLink to={url} stripHash={true} title={name}><span className='title'>{name}</span></AnchorLink>
            </li>
          )
        })}
        </ul>
      </nav>
    )
}

export default SectionNav