import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import Hero from '../components/Hero'
import ExperienceSection from '../components/Experience'
import AboutSection from '../components/About'
import ConnectSection from '../components/Connect'

const Index = ({ data }) => {
  return (
    <Layout>
      <Hero content={data.hero.edges} />
      <ExperienceSection content={data.experience.edges} />
      <AboutSection content={data.about.edges} />
      <ConnectSection content={data.connect.edges} />
    </Layout>
  )
}

export const pageQuery = graphql`
{
    hero: allMdx(filter: {fileAbsolutePath: {regex: "/hero/"}}) {
      edges {
        node {
          frontmatter {
            heading
          }
          body
        }
      }
    }
    experience: allMdx(filter: {fileAbsolutePath: {regex: "/experience/"}}) {
      edges {
        node {
          frontmatter {
            heading
            accent
            subheading
          }
          body
        }
      }
    }
    about: allMdx(filter: {fileAbsolutePath: {regex: "/about/"}}) {
      edges {
        node {
          frontmatter {
            heading
            accent
            subheading
          }
          body
        }
      }
    }
    connect: allMdx(filter: {fileAbsolutePath: {regex: "/connect/"}}) {
      edges {
        node {
          frontmatter {
            heading
            accent
            subheading
          }
          body
        }
      }
    }
  } 
`

export default Index