import React from 'react'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import Icon from "../components/Icon"
import { socialMedia } from '../config/index'

const Connect = ({ content }) => {
    const { frontmatter, body } = content[0].node
    return (
        <section id='connect'>
            <h1>{frontmatter.heading} <span className='accent'>{frontmatter.accent}</span> <span className='dash'>—</span></h1>
            <h2 className='subheading'>{frontmatter.subheading}</h2>
            <MDXRenderer>{body}</MDXRenderer>
            <ul className='social'>
                {socialMedia.map(({ name, username, url }, key) => {
                return (
                <li key={key}>
                    <a href={url} alt={`${name} icon`} rel='noreferrer noopener' target='_blank'>
                        <Icon name={name} />{ username }
                    </a>
                </li>
                )
                })}
            </ul>
        </section>
    )
}

export default Connect