import React from 'react'
import { MDXRenderer } from 'gatsby-plugin-mdx'

const Hero = ({ content }) => {
    const { frontmatter, body } = content[0].node
    return (
        <section id='hero'>
            <h1 className="display-text">
                {frontmatter.heading}
            </h1>
            <MDXRenderer>{body}</MDXRenderer>
        </section>
    )
}

export default Hero