import React from 'react'
import IconGithub from './icons/Github'
import IconInstagram from './icons/Instagram'
import IconLinkedIn from './icons/LinkedIn'
import IconTwitter from './icons/Twitter'
import IconArrow from './icons/Arrow'

const Icon = ({ name }) => {
  switch (name.toLowerCase()) {
    case 'linkedin':
      return <IconLinkedIn />
    case 'twitter':
    return <IconTwitter />
    case 'instagram':
      return <IconInstagram />
    case 'github':
      return <IconGithub />
    case 'arrow':
        return <IconArrow />
    default:
      return null
  }
}
export default Icon