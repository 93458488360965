import React from 'react'

const Footer = () => {
  return (
    <footer>
        <p>Designed and built by me. &copy; {new Date().getFullYear()}</p>
    </footer>
  )
}

export default Footer