import React from 'react'
import { MDXRenderer } from 'gatsby-plugin-mdx'

const About = ({ content }) => {
    const { frontmatter, body } = content[0].node
    return (
        <section id='about'>
            <h1>{frontmatter.heading} <span className='accent'>{frontmatter.accent}</span> <span className='dash'>—</span></h1>
            <h2 className='subheading'>{frontmatter.subheading}</h2>
            <MDXRenderer>{body}</MDXRenderer>
        </section>
    )
}

export default About